import React, { createContext, useContext, useState, useEffect } from 'react';
import { fetchWithConfig } from '../config/api';
import { endpoints } from '../config/config';

const CategoryContext = createContext();

export const useCategories = () => {
  const context = useContext(CategoryContext);
  if (!context) {
    throw new Error('useCategories must be used within a CategoryProvider');
  }
  return context;
};

export const CategoryProvider = ({ children }) => {
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetchWithConfig(endpoints.categories.all);
        if (!response.ok) {
          throw new Error('Failed to fetch categories');
        }
        const data = await response.json();
        setCategories(data);
      } catch (err) {
        console.error('Error fetching categories:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCategories();
  }, []);

  const getCategoryByName = (name) => {
    if (!name) return null;
    
    // Trim whitespace from name and input
    const normalizedName = name.trim();
    const category = categories.find(cat => cat.name.trim() === normalizedName);
    
    if (!category) {
      console.warn(`Category not found: "${normalizedName}". Available categories:`, 
        categories.map(c => `"${c.name}"`).join(', '));
    }
    
    return category;
  };

  const getCategoryById = (id) => {
    if (!id) return null;
    const category = categories.find(cat => cat.id === id);
    
    if (!category) {
      console.warn(`Category ID not found: ${id}. Available IDs:`, 
        categories.map(c => `${c.id} (${c.name})`).join(', '));
    }
    
    return category;
  };

  return (
    <CategoryContext.Provider value={{ 
      categories, 
      loading, 
      error, 
      getCategoryByName,
      getCategoryById
    }}>
      {children}
    </CategoryContext.Provider>
  );
};
